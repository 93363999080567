<template>
  <div>
    <v-card-text>
      <v-select
        outlined
        hide-details
        dense
        label="綁定權限"
        item-text="name"
        item-value="type"
        :items="authList"
        v-model="formData.authSelected"
      ></v-select>
      <br>
      <v-select
        outlined
        hide-details
        dense
        label="會員名稱"
        item-text="name"
        item-value="id"
        :items="memberList"
        v-model="formData.memberselected"
      ></v-select>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    popupConfig: Object,
  },
  data: () => ({
    formData: {
      authSelected: '',
      memberselected: ''
    }
  }),
  computed: {
    authList() {
      return this.popupConfig.authList
    },
    memberList() {
      return this.popupConfig.memberList
    },
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', this.formData)
      },
    }
  },
};
</script>